import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";

import TextField from "../TextField";
import Checkbox from "../Checkbox";

import {
  MainFormValue, // eslint-disable-line  no-unused-vars
  useStyles,
} from "./constants";

interface ContactFormProps {
  prefix: "dancer1" | "dancer2";
  isContactOptional?: boolean;
}

const ContactForm = ({ prefix, isContactOptional }: ContactFormProps) => {
  const classes = useStyles();
  const { values }: { values: MainFormValue } = useFormikContext();

  return (
    <>
      <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
        <TextField
          required
          name={`${prefix}.firstName`}
          label="Voornaam"
          fullWidth
          autoComplete={`${prefix} given-name`}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
        <TextField
          required
          name={`${prefix}.lastName`}
          label="Achternaam"
          fullWidth
          autoComplete={`${prefix} family-name`}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <TextField
          type="email"
          autoComplete={`${prefix} email`}
          required
          name={`${prefix}.email`}
          label="Email"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <TextField
          autoComplete={`${prefix} tel`}
          required
          name={`${prefix}.phoneNumber`}
          label="Telefoonnummer"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Checkbox
          name={`${prefix}.subscribeNewsletter`}
          label="Ja, hou mij op de hoogte van alle nieuwtjes van Dance Square via de elektronische nieuwsbrief"
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Box mt={2}>
          <Typography variant="subtitle1">Adres:</Typography>
        </Box>
      </Grid>
      {isContactOptional && (
        <Grid item xs={12} className={classes.gridItem}>
          <Checkbox
            name="useSameContact"
            label="Zelfde adres als mijn danspartner"
          />
        </Grid>
      )}
      {(!isContactOptional || !values.useSameContact) && (
        <>
          <Grid item xs={9} className={classes.gridItem}>
            <TextField
              required
              name={`${prefix}.address.street`}
              label="Straat"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            <TextField
              required
              name={`${prefix}.address.number`}
              label="Nr."
              fullWidth
            />
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <TextField
              required
              name={`${prefix}.address.zip`}
              label="Postcode"
              fullWidth
              autoComplete="postal-code"
            />
          </Grid>
          <Grid item xs={8} className={classes.gridItem}>
            <TextField
              required
              name={`${prefix}.address.city`}
              label="Woonplaats"
              fullWidth
            />
          </Grid>
        </>
      )}
    </>
  );
};

ContactForm.defaultProps = {
  isContactOptional: false,
};

export default ContactForm;
