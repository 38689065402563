import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Formik, Form as FormikForm } from "formik";
import * as Sentry from "@sentry/react";
import Alert from "@material-ui/lab/Alert";
import TextField from "../TextField";
import Checkbox from "../Checkbox";

import {
  DANCES,
  DANCE_LIST_1,
  DANCE_LIST_2,
  DEFAULT_FORM_VALUES,
  // eslint-disable-next-line no-unused-vars
  MainFormValue,
  SPACING,
  useStyles,
  saveFormValues,
} from "./constants";
import ContactForm from "./ContactForm";

interface FormProps {
  onReplied?: (name: string) => void;
}

const Form = ({ onReplied }: FormProps) => {
  const initialValues = DEFAULT_FORM_VALUES;
  const classes = useStyles();

  const [hasError, setHasError] = useState(false);
  const handleSubmit = (
    values: MainFormValue,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setHasError(false);
    saveFormValues(values)
      .then(() => {
        if (onReplied === undefined) {
          return;
        }
        setSubmitting(false);
        onReplied(values.dancer1.firstName);
      })
      .catch((error: Error) => {
        setSubmitting(false);
        setHasError(true);
        Sentry.withScope(() => {
          Sentry.captureException(error);
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formProps={{ autoComplete: "on" }}
    >
      {({ isSubmitting, values }) => (
        <FormikForm>
          <Grid container spacing={SPACING} className={classes.gridContainer}>
            <ContactForm prefix="dancer1" />
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={3} mb={3} className={classes.separator} />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Checkbox name="hasPartner" label="Ik heb een danspartner" />
            </Grid>
            {values.hasPartner && (
              <ContactForm prefix="dancer2" isContactOptional />
            )}
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={3} mb={3} className={classes.separator} />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Box>
                <Typography variant="h5" component="h3">
                  Cursussen:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={1}>
                <Typography variant="subtitle1" component="h4">
                  Donderdag: (Locatie: Eikenbos Boskestraat 3, Lummen)
                </Typography>
              </Box>
            </Grid>
            {DANCE_LIST_1.map((danceKey) => (
              <Grid key={danceKey} item xs={12} className={classes.gridItem}>
                <Checkbox
                  name={`dances.${danceKey}`}
                  label={DANCES[danceKey]}
                />
              </Grid>
            ))}
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={1}>
                <Typography variant="subtitle1" component="h4">
                  Zondag: (Locatie: Casino, Varenstraat 22, Houthalen)
                </Typography>
              </Box>
            </Grid>
            {DANCE_LIST_2.map((danceKey) => (
              <Grid key={danceKey} item xs={12} className={classes.gridItem}>
                <Checkbox
                  name={`dances.${danceKey}`}
                  label={DANCES[danceKey]}
                />
              </Grid>
            ))}
            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                multiline
                rows={4}
                name="message"
                label="Andere opmerkingen, vertellingen, verzoekjes... kan je hier kwijt"
                disabled={isSubmitting}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={2} mb={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                >
                  Verzenden
                </Button>
              </Box>
            </Grid>
            {hasError && (
              <Grid item xs={12} className={classes.gridItem}>
                <Box mt={2} mb={1}>
                  <Alert severity="info">
                    Something went wrong. If the problem persists, please report
                    it.
                  </Alert>
                </Box>
              </Grid>
            )}
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};

Form.defaultProps = {
  onReplied: undefined,
};

export default Form;
